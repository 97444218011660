import { createRouter, createWebHistory } from 'vue-router'

// const base = ''
const tit = '云南省大学科技园--'
const routes = [
  // {
  //   path: base + '/',
  //   name: 'home',
  //   component: () => import('@/views/home')
  //   //redirect: base + '/sys/index',
  //   // redirect: base + '/home'
  //   // children: [
  //   //   // { path: '/home', redirect: '/' },
  //   //   { path: base + '/sys/index', name: 'index', meta: { keepAlive: true }, component: () => import('@/views/sys/index.vue') },
      
  //   //   // { path: base + '/info/sort', name: 'sort', meta: { keepAlive: true }, component: () => import('@/components/info/sort.vue') },
  //   //   // { path: base + '/info/newsadd:sid', name: 'newsadd', meta: { keepAlive: true }, component: () => import('@/components/info/newsadd.vue') },
  //   //   // { path: base + '/info/news:sid', name: 'news', meta: { keepAlive: true }, component: () => import('@/components/info/news.vue') },
  //   // ]
  // },
  {
    path: '/',
    redirect: '/index'    
  },  
  {
    path: '/index',
    meta: { 
      // keepAlive: true,
      title: '首页'
    },
    component: () => import('@/views/layout'),
    // redirect: base + '/index',    
    children: [{
      path: '/index',
      meta: { 
        // keepAlive: true,
        title: tit + '首页'
      },
      component: () => import('@/views/index')
    },
    {
      path: '/index1',
      meta: { 
        // keepAlive: true,
        title: tit + '首页'
      },
      component: () => import('@/views/index1')
    },
    {
      path: '/login',
      meta: {
        title: tit + '用户登录'
      },
      component: () => import('@/views/login')
    },
    {
      path: '/regist',
      meta: {
        title: tit + '用户注册'
      },
      component: () => import('@/views/regist')
    },
    {
      path: '/news/index',
      meta: {
        title: tit + '文章列表'
      },
      component: () => import('@/views/news/index')
    },
    {
      path: '/news/news',
      meta: {
        title: tit + '文章正文'
      },
      component: () => import('@/views/news/news')
    }, 
    {
      path: '/message',
      redirect: '/message/index'    
    },
    {
      path: '/message/index',
      meta: {
        title: tit + '通知公告列表'
      },
      component: () => import('@/views/message/index')
    },
    {
      path: '/message/message',
      meta: {
        title: tit + '通知公告正文'
      },
      component: () => import('@/views/message/message')
    },  
    {
      path: '/resource',
      redirect: '/resource/index'    
    },  
    {
      path: '/resource/index:key?',
      meta: {
        title: tit + '产学研资源列表'
      },
      component: () => import('@/views/resource/index')
    },
    {
      path: '/resource/resource',
      meta: {
        title: tit + '产学研资源正文'
      },
      component: () => import('@/views/resource/resource')
    },
    {
      path: '/activity',
      redirect: '/activity/index'    
    }, 
    {
      path: '/activity/index',
      meta: {
        title: tit + '产学研对接列表'
      },
      component: () => import('@/views/activity/index')
    },
    {
      path: '/activity/activity',
      meta: {
        title: tit + '产学研对接正文'
      },
      component: () => import('@/views/activity/activity')
    },
    {
      path: '/talents/tutorlist',
      meta: {
        title: tit + '创业导师列表'
      },
      component: () => import('@/views/talents/tutorlist')
    },
    {
      path: '/talents/tutor',
      meta: {
        title: tit + '创业导师正文'
      },
      component: () => import('@/views/talents/tutor')
    },
    {
      path: '/bb',
      meta: {
        title: tit + 'bb'
      },
      component: () => import('@/views/bb')
    }]    
  },
  // 用户管理
  {
    path: '/member',
    redirect: '/member/index'    
  }, 
  {
    path: '/member/index',
    meta: { 
      // keepAlive: true,
      title: '注册会员用户管理'
    },
    component: () => import('@/views/member/layout'),       
    children: [
    {
      path: '/member/index',
      meta: {
        title: tit + '欢迎用户'
      },
      component: () => import('@/views/member/index')
    }, {
      path: '/member/useredit',
      meta: {
        title: tit + '用户信息修改'
      },
      component: () => import('@/views/member/useredit')
    }, {
      path: '/member/pwdedit',
      meta: {
        title: tit + '用户密码修改'
      },
      component: () => import('@/views/member/pwdedit')
    },{
      path: '/member/order',
      meta: {
        title: tit + '订单管理'
      },
      component: () => import('@/views/member/order')
    }]
  }
]
const router = createRouter({
  // 去掉#号
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHistory(),
  //history: createWebHashHistory(),
  // 记录页面滚动位置
  // scrollBehavior (to, from, savedPosition) {
  //   if (savedPosition) { // 如果savedPosition存在，滚动条会自动跳到记录的值的地方
  //     return savedPosition
  //   } else {
  //     return { x: 0, y: 0 } // savedPosition也是一个记录x轴和y轴位置的对象
  //   }
  // },
  routes
})

export default router