import axios from 'axios'
import Storage from '@/utils/storage'
import router from '../router'
import { ElLoading } from 'element-plus'
// import router from '../router'
// axios.defaults.withCredentials = true

// axios.defaults.baseURL = 'http://localhost:34011/api'
// function get (url, params = {}) {
//   return new Promise((resolve, reject) => {
//     axios({
//       method: 'get',
//       url,
//       params
//     }).then((data) => {
//       resolve(data)
//     }).catch(err => {
//       reject(err)
//     })
//   })
// }
// function post (url, data = {}) {
//   return new Promise((resolve, reject) => {
//     axios({
//       method: 'post',
//       url,
//       data
//     }).then((data) => {
//       resolve(data)
//     }).catch(err => {
//       reject(err)
//     })
//   })
// }
// function del (url, data = {}) {
//   return new Promise((resolve, reject) => {
//     axios({
//       method: 'delete ',
//       url,
//       data
//     }).then((data) => {
//       resolve(data)
//     }).catch(err => {
//       reject(err)
//     })
//   })
// }
// function put (url, data = {}) {
//   return new Promise((resolve, reject) => {
//     axios({
//       method: 'put ',
//       url,
//       data
//     }).then((data) => {
//       resolve(data)
//     }).catch(err => {
//       reject(err)
//     })
//   })
// }
// export { get, post, del, put }

axios.interceptors.request.use(config => {
  // 设置以 form 表单的形式提交参数，如果以 JSON 的形式提交表单，可忽略
  // if (config.method === 'post') {
  //   // JSON 转换为 FormData
  //   const formData = new FormData()
  //   Object.keys(config.data).forEach(key => formData.append(key, config.data[key]))
  //   config.data = formData
  // }
  const ui = Storage.get('dxkjy-webui')  
  if (ui) {    
    // config.headers.Authorization = 'Bearer ' + ui.token
    config.headers.Authorization = ui.token
    // config.headers.Accept = 'application/json'
    // config.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
  }
  return config
}, err => {
  return Promise.reject(err)
})

axios.interceptors.response.use((response) => {
  // 判断一下响应中是否有 token，如果有就直接使用此 token 替换掉本地的 token。你可以根据你的业务需求自己编写更新 token 的逻辑
  var token = response.headers.retoken
  if (token) {
    Storage.set('xucmstoken', token)
  }
  return response
}, (err) => {
  if (err.response) {
    switch (err.response.status) {
      case 401:
        // 这里写清除token的代码
        router.replace({
          path: '/login',
          // 登录成功后跳入浏览的当前页面
          query: { redirect: router.currentRoute.fullPath }
        })
    }
  }
  return Promise.reject(err)
})

// 自定义的 axios 响应拦截器
// axios.interceptors.response.use((response) => {
//   // 判断一下响应中是否有 token，如果有就直接使用此 token 替换掉本地的 token。你可以根据你的业务需求自己编写更新 token 的逻辑
//   var token = response.headers.authorization
//   if (token) {
//   // 如果 header 中存在 token，那么触发 refreshToken 方法，替换本地的 token
//     axios.defaults.headers.common['Authorization'] = token
//   }
//   return response
// }, (err) => {
//   if (err.response) {
//     switch (err.response.status) {
//       case 401:
//         // 这里写清除token的代码
//         router.replace({
//           path: 'login',
//           // 登录成功后跳入浏览的当前页面
//           query: { redirect: router.currentRoute.fullPath }
//         })
//     }
//   }
//   return Promise.reject(err)
// })

class http {
  static get (url, params) {
    return axios.get(url, params)
  }

  static post (url, params) {
    return axios.post(url, params)
  }

  static down (url, params) {
    return axios({
      method: 'get',
      url: url,
      params: params,
      responseType: 'blob'
    })
  }

  static get1 (url, params) {    
    const loading = ElLoading.service({
      lock: true,
      text: '正在加载中...',
      // spinner: 'el-icon-loading',
      // background: 'rgba(0, 0, 0, 0.7)'
    })
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url,
        params
      }).then((data) => {
        resolve(data)
      }).catch(err => {
        // console.log('err=', err)
        reject(err)        
      }).finally (() => {
        loading.close()
      })
    })    
  }

  static post1 (url, params) {    
    const loading = ElLoading.service({
      lock: true,
      text: '正在加载中...',
      // spinner: 'el-icon-loading',
      // background: 'rgba(0, 0, 0, 0.7)'
    })
    return new Promise((resolve, reject) => {
      axios.post(url, params)
      // axios({
      //   method: 'post',
      //   url,
      //   params
      // })
      .then((data) => {        
        resolve(data)
      }).catch(err => {
        // console.log('err=', err)
        reject(err)        
      }).finally (() => {
        loading.close()
      })
    })    
  }
}
export default http