import Storage from '@/utils/storage'
// 通用
const ui = Storage.get('dxkjy-webui')
//服务端地址（图片）
const serverUrl = process.env.VUE_APP_URL
// let homeUrl = ''
// if (ui != null) {
//   homeUrl = 'http://' + ui.siteUrl
// }
const baseUrl = process.env.VUE_APP_API
// 登录
const login = baseUrl + '/ut/login'
const logout = baseUrl + '/ut/logout'
const useradd = baseUrl + '/ut/system/user/save'
const useredit = baseUrl + '/ut/system/user/update'
const userinfo = baseUrl + '/ut/getUserInfo'
const userinfoByuname = baseUrl + '/ut/system/user/getUserInfoByUsername'
const userPwdEdit = baseUrl + '/ut/system/user/updatePass'

//资源分页查询
const resourcelist = baseUrl + '/ut/resourcetable/page'
const resourceshow = baseUrl + '/ut/resourcetable/getResourceTableById'

//文章分页查询
const newslist = baseUrl + '/ut/content/page'
const newsshow = baseUrl + '/ut/content/getContentById'

// 通知公告分页查询
const messagelist = baseUrl + '/ut/postmessage/page'
const messageshow = baseUrl + '/ut/postmessage/getPostMessageById'

//站点导航
const menulist = baseUrl + '/ut/navMenu/page'

// 根据类型查询字典项
const dictItemlist = baseUrl + '/ut/system/dict-data/getDictDataListByDictType'

// 添加资源申请(添加订单)
const orderAdd = baseUrl + '/ut/resourceapply/save'
// 产品订单分页查询
const orderlist = baseUrl + '/ut/resourceapply/page'
// 添加订单投诉意见
const complaintAdd = baseUrl + '/ut/complaint/save'


// // 用户信息
// const userlist = baseUrl + '/user/list'
// const userid = baseUrl + '/user'
// const usersave = baseUrl + '/user/save'
// const userdel = baseUrl + '/user/del'
// const userenable = baseUrl + '/user/enable'
// const usergetusertypelist = baseUrl + '/user/getusertypelist'

export default {  
  serverUrl,
  ui,
  login,
  logout,
  useradd,
  useredit,
  userinfo,
  userinfoByuname,
  userPwdEdit,
  resourcelist,
  resourceshow,
  newslist,
  newsshow,
  messagelist,
  messageshow,
  menulist,
  dictItemlist,
  orderAdd,
  orderlist,
  complaintAdd
}