import { createApp } from 'vue'
import App from '@/App.vue'
import router from '@/router'
// import store from './store'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import 'element-plus/dist/index.css'
// 导入所有图标并进行全局注册
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

// 引入全局的样式文件
import '@/assets/css/global.css'

import http from '@/utils/http'
import Apis from '@/utils/apis'
import Storage from '@/utils/storage'

// 在每一个meta里面设置页面的title名称
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

const app = createApp(App)
app.use(router)
// app.use(store)
app.use(ElementPlus, {
  locale: zhCn
})
// 导入所有图标并进行全局注册
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

// 挂载全局方法 将axios挂载到Vue的原型对象上
app.config.globalProperties.$http = http
app.config.globalProperties.$api = Apis
app.config.globalProperties.$storage = Storage
app.mount('#app')
